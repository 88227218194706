<template>
  <div>
    <div class="filter-container top50">
      <div style="color:red;font-size: 14px;">
        提示：印花税核定征收请直接填写核定比例，如核定比例为0，默认不是核定征收
      </div>
      <div>
        <!-- <qzf-video vid="4467be537466bcfba6353f4a1c7fa3d1_4"></qzf-video> -->
        <span style="font-size: 14px;">是否隐藏过期税种：</span>
        <el-switch
          v-model="expiredTypes"
          style="margin-right: 5px;"
          :active-value="true"
          :inactive-value="false"
          size="small"
          @change="changeExpiredTypes"
        />
        <el-button
          type="primary"
          size="small"
          @click="addTax()"
          v-if="$buttonStatus('sjxx_xzsz')"
        >
          <el-icon> <CirclePlus /> </el-icon><span> 新增税种</span>
        </el-button>
        <!-- <el-button type="success" size="small" @click="getNew()">
          <el-icon><Pointer /></el-icon><span  > 税种更新</span>
        </el-button> -->
      </div>
    </div>
    <el-table
      :height="contentStyleObj"
      :key="tableKey"
      :data="listS"
      border
      style="width: 100%;"
      @sort-change="sortChange"
      v-loading="loading"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column label="序号" align="center" width="55" type="index" fixed>
      </el-table-column>
      <el-table-column label="征收项目" align="center" min-width="160" fixed>
        <template #default="{row}">
          <span>{{ row.collectionProject }}</span>
        </template>
      </el-table-column>
      <el-table-column label="征收品目" align="center" min-width="150" fixed>
        <template #default="scope">
          <!-- <el-select v-if="row.itemList.length == 1" size="small" v-model="row.itemList[0].item_mame" style="width: 90%" filterable clearable>
            <el-option v-for="item in row.itemList" :key="item.id" :label="item.item_mame" :value="item.item_mame" >
            </el-option>
          </el-select> -->
          <el-select
            v-if="scope.row.itemList && scope.row.itemList.length != 1"
            size="small"
            v-model="scope.row.collectionItems"
            style="width: 90%"
            filterable
            clearable
            placeholder="请选择征收品目"

          >
            <el-option
              v-for="item in scope.row.itemList"
              :key="item.id"
              :label="item.item_mame"
              :value="item.item_mame"
            >
            </el-option>
          </el-select>
          <el-input
            style="width: 90%"
            size="small"
            v-else-if="scope.row.itemList && scope.row.itemList.length == 1"
            v-model="scope.row.itemList[0].item_mame"
          ></el-input>
          <el-input style="width: 90%"  v-model="scope.row.collectionItems" size="small" v-else></el-input>
        </template>
      </el-table-column>
      <el-table-column label="征收子目" align="center" min-width="150">
        <template #default="{row}">
          <el-input v-model="row.collectionSub" style="height:25px;"> </el-input>
          <!-- <span>{{row.collectionSub}}</span> -->
        </template>
      </el-table-column>
      <el-table-column label="申报周期" align="center" min-width="180">
        <template #default="{row}">
          <div>
            <!-- 这个地方改成多选的 但是 只限月份的 -->
            <el-select
              size="small"
              v-model="row.types"
              multiple
              collapse-tags
              collapse-tags-tooltip
              style="width: 90%"
              @change="handleDisabledStatus(row)"
            >
              <el-option
                v-for="item in row.options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              >
              </el-option>
            </el-select>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="征收方式" align="center" min-width="120">
        <template #default="{row}">
          <span>{{ row.collectionAgency }}</span>
        </template>
      </el-table-column>
      <el-table-column label="核定经营额" align="center" min-width="120">
        <template #default="{row}">
          <el-input
          v-if="row.dqde == '定额核定'"
            placeholder=""
            v-model="row.hdjye"
            class="filter-item"
            size="small"
            style="width: 100px;text-align: center;"
          />
          <span v-else>{{ row.hdjye }}</span>
        </template>
      </el-table-column>
      <el-table-column label="启用时间" align="center" min-width="150">
        <template #default="{row}">
          <el-input
            placeholder=""
            v-model="row.validityPeriodStart"
            class="filter-item"
            size="small"
            style="width: 100px"
          />
        </template>
      </el-table-column>
      <el-table-column label="结束时间" align="center" min-width="150">
        <template #default="{row}">
          <el-input
            placeholder=""
            v-model="row.validityPeriodEnd"
            class="filter-item"
            size="small"
            style="width: 100px"
          />
        </template>
      </el-table-column>

      <el-table-column label="税率（小数）" align="center" min-width="120">
        <template #default="{row}">
          <el-input
            placeholder=""
            v-model="row.rate"
            class="filter-item"
            size="small"
            style="width: 70px;text-align: center;"
          />
        </template>
      </el-table-column>
      <el-table-column label="申报表" align="center" min-width="150">
        <template #default="{row}">
          <div v-if="row.collectionProject == '个人所得税'">
            <el-select size="small" v-model="row.dqde" clearable>
              <el-option label="定额核定" value="定额核定"></el-option>
              <el-option label="定率征收" value="定率征收"></el-option>
            </el-select>
          </div>
          <div v-else>
            <el-input
              placeholder=""
              v-model="row.dqde"
              class="filter-item"
              size="small"
              style="width: 120px;text-align: center;"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column label="核定征收（印花）" min-width="180" align="center">
        <template #default="{row}">
          <div v-if="row.collectionProject == '印花税'">
            <span>核定比例(小数)</span>
            <span>
              <el-input
                placeholder=""
                type="number"
                v-model.number="row.yhHdbl"
                style="width: 100px"
                class="filter-item"
                size="small"
              />
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="启用状态" min-width="200" align="center">
        <template #default="{row}">
          <el-radio-group v-model="row.qyzt">
            <el-radio :label="0">启用</el-radio>
            <el-radio :label="1">不启用</el-radio>
          </el-radio-group>
        </template>
      </el-table-column>
      <el-table-column
        label="印花次报启用状态(如没有次报不生成报表)"
        min-width="300"
        align="center"
      >
        <template #default="{row}">
          <div v-if="row.collectionProject == '印花税' && row.type == '次'">
            <el-radio-group v-model="row.yhscbqyzt">
              <el-radio :label="0">启用</el-radio>
              <el-radio :label="1">不启用</el-radio>
            </el-radio-group>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="130" align="center">
        <template #default="scope">
          <i
            @click="taxSave(scope.row)"
            class="iconfont icon-baocun"
            v-if="$buttonStatus('sjxx_bcxg')"
          ></i>
          <i
            @click="taxDel(scope.row.id)"
            class="iconfont icon-shanchu"
            v-if="$buttonStatus('qyxx_szxx_sc')"
          ></i>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 10px;text-align: right;">
      <el-button
        type="primary"
        size="small"
        @click="saveBatch()"
        icon="Checked"
        :disabled="!$buttonStatus('sjxx_bcxg')"
      >
        批量保存
      </el-button>
    </div>
  </div>
  <addComInfo ref="addComInfo" @success="getList"></addComInfo>
</template>

<script>
import addComInfo from "./addComInfo.vue";
import {
  taxCategoryList,
  delOneTaxCategory,
  saveTaxCategory,
} from "@/api/taxCalculation.js";
import { currentAccountPeriod } from '@/utils'

export default {
  name: "information",
  components: { addComInfo },
  data() {
    return {
      expiredTypes: undefined,
      sels: [],
      listS: [],
      type: [
        {
          value: "月",
          label: "月报",
          disabled:false
        },
        {
          value: "季",
          label: "季报",
          disabled:false
        },
        {
          value: "年",
          label: "年报",
          disabled:false
        },
        {
          value: "次",
          label: "次报",
          disabled:false
        },
        {
          value: "不申报",
          label: "不申报",
          disabled:false
        },
        {
          value: "01",
          label: "一月",
          disabled:false
        },
        {
          value: "02",
          label: "二月",
          disabled:false
        },
        {
          value: "03",
          label: "三月",
          disabled:false
        },
        {
          value: "04",
          label: "四月",
          disabled:false
        },
        {
          value: "05",
          label: "五月",
          disabled:false
        },
        {
          value: "06",
          label: "六月",
          disabled:false
        },
        {
          value: "07",
          label: "七月",
          disabled:false
        },
        {
          value: "08",
          label: "八月",
          disabled:false
        },
        {
          value: "09",
          label: "九月",
          disabled:false
        },
        {
          value: "10",
          label: "十月",
          disabled:false
        },
        {
          value: "11",
          label: "十一月",
          disabled:false
        },
        {
          value: "12",
          label: "十二月",
          disabled:false
        },
      ],
      comId: "",
      loading: false,
      listSTemp:[],
    };
  },
  created() {
    this.contentStyleObj = this.$getHeight(240);
  },
  mounted(){
    this.expiredTypes = localStorage.getItem("hideTaxCate") == 'true'
    this.getList()
  },
  methods: {
    changeExpiredTypes(e){
      this.loading = true;
      this.expiredTypes = e
      localStorage.setItem("hideTaxCate",this.expiredTypes);
      if(this.expiredTypes){
        const currentPeriod = this.$store.getters["user/comInfo"].period;
        const currentYear = currentPeriod.substring(0, 4);
        const currentMonth = parseInt(currentPeriod.substring(4, 6), 10);
        // 转换为日期对象
        const comparisonDate  = new Date(currentYear, currentMonth - 1, 1);
        const currentPeriodEndDate = new Date(currentYear, currentMonth, 0); 
        const filteredData = this.listS.filter(item => {
            if (!item.validityPeriodEnd) {
              // 如果为空，则直接返回 true，保留该项
              return true;
            }
            const validityEndDate  = new Date(item.validityPeriodEnd.replace(/-/g, '/')); 
            if (isNaN(validityEndDate.getTime())) {
              return true; // 保留无效日期的项
            }
            return !(validityEndDate < comparisonDate);
        });
        this.listS = filteredData
        this.loading = false;
      }else{
        this.listS = this.listSTemp
        this.loading = false;
      }
    },
    addTax() {
      if(this.$store.getters["user/comInfo"].comId){
        if(this.$checkSettleStatus()) return
        this.$refs.addComInfo.openSalary(this.comId);
      }else{
        this.$refs.addComInfo.openSalary(this.comId);
      }
    },
    //税种信息列表
    getList() {
      this.loading = true;
      // if(this.activeName == 'information') {
      this.comId = this.$store.getters["commons/params"].editCustomerComId * 1;
      if (this.$store.getters["user/comInfo"].comId * 1) {
        this.comId = this.$store.getters["user/comInfo"].comId * 1;
      } else if (
        this.$store.getters["commons/params"].editCustomerComId.id * 1
      ) {
        this.comId = this.$store.getters["commons/params"].editCustomerComId.id;
      }
      taxCategoryList({ comId: this.comId }).then((res) => {
        this.loading = false;
        if (res.data.data.list) {
          let data = res.data.data.list;
          data.map((v) => {
            v.types = v.type.split(",");
          });
          this.listS = data;
          this.listSTemp = this.listS
          this.changeExpiredTypes(this.expiredTypes)
          this.changeType()
        } else {
          this.listS = [];
        }
      });
    },
    //税种信息删除
    taxDel(id) {
      if(this.$store.getters["user/comInfo"].comId){
        if(this.$checkSettleStatus()) return
        this.$confirm("是否删除此税种?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          delOneTaxCategory({ id: id }).then((res) => {
            if (res.data.msg == "success") {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.getList();
            this.$bus.emit('taxUpdate')
            }
          });
        });
      }else{
        this.$confirm("是否删除此税种?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          delOneTaxCategory({ id: id }).then((res) => {
            if (res.data.msg == "success") {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.getList();
            this.$bus.emit('taxUpdate')
            }
          });
        });
      }
    },
    taxSave(row) {
      // 判断已结账未结账
      if(this.$store.getters["user/comInfo"].comId){
        if(this.$checkSettleStatus()) return
        row.type = row.types.join(",");
        if (!row.type || !row.validityPeriodStart || !row.validityPeriodEnd) {
          this.$message.warning("请将税种信息填写完整");
          return;
        }
        // 品目不判断
        // if (row.itemList && row.itemList.length != 1) {
        //   if (!row.collectionItems) {
        //     this.$message.warning("请选择征收品目");
        //     return;
        //   }
        // }
        row.validityPeriodStart = row.validityPeriodStart.trim()
        row.validityPeriodEnd = row.validityPeriodEnd.trim()
        saveTaxCategory({ list: [row] }).then((res) => {
          if (res.data.msg == "success") {
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.getList();
            this.$bus.emit('taxUpdate')
          }
        });
      }else{
        row.type = row.types.join(",");
        if (!row.type || !row.validityPeriodStart || !row.validityPeriodEnd) {
          this.$message.warning("请将税种信息填写完整");
          return;
        }
        // 品目不判断
        // if (row.itemList && row.itemList.length != 1) {
        //   if (!row.collectionItems) {
        //     this.$message.warning("请选择征收品目");
        //     return;
        //   }
        // }
        row.validityPeriodStart = row.validityPeriodStart.trim()
        row.validityPeriodEnd = row.validityPeriodEnd.trim()
        saveTaxCategory({ list: [row] }).then((res) => {
          if (res.data.msg == "success") {
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.getList();
            this.$bus.emit('taxUpdate')
          }
        });
      }
      
    },
    saveBatch() {
      // 判断已结账未结账
      if(this.$store.getters["user/comInfo"].comId){
        if(this.$checkSettleStatus()) return
        let status = false;
        this.listS.map((v) => {
          v.type = v.types.join(",");
          if (!v.type || !v.validityPeriodStart || !v.validityPeriodEnd) {
            status = true;
          }
          // 品目不判断
          // if (v.itemList && v.itemList.length != 1) {
          //   if (!v.collectionItems) {
          //     status = true;
          //   }
          // }
        });
        if (status) {
          this.$message.warning("请将税种信息填写完整");
          return;
        }
        this.listS.map(e=>{
          e.validityPeriodStart = e.validityPeriodStart.trim()
          e.validityPeriodEnd = e.validityPeriodEnd.trim()
        })
        saveTaxCategory({ list: this.listS }).then((res) => {
          if (res.data.msg == "success") {
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.getList();
            this.$bus.emit('taxUpdate')
          }
        });
      }else{
        let status = false;
        this.listS.map((v) => {
          v.type = v.types.join(",");
          if (!v.type || !v.validityPeriodStart || !v.validityPeriodEnd) {
            status = true;
          }
          if (v.itemList && v.itemList.length != 1) {
            if (!v.collectionItems) {
              status = true;
            }
          }
        });
        if (status) {
          this.$message.warning("请将税种信息填写完整");
          return;
        }
        this.listS.map(e=>{
          e.validityPeriodStart = e.validityPeriodStart.trim()
          e.validityPeriodEnd = e.validityPeriodEnd.trim()
        })
        saveTaxCategory({ list: this.listS }).then((res) => {
          if (res.data.msg == "success") {
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.$bus.emit('taxUpdate')
            this.getList();
          }
        });
      }
    },
    setDisabledStatus(item, typeToCheck, valueToEnable){
      if (item.types.includes(typeToCheck)) {
        item.options.forEach((v) => {
          v.disabled = v.value !== valueToEnable;
        });
      }
    },
    changeType(){
      this.listS.map(item=>{
        item.options = this.$qzfCopy(this.type)
        this.handleDisabledStatus(item);
      })
    },
    containsAnyTwo(sourceArray, targetArray) {
      for (let i = 0; i < targetArray.length - 1; i++) {
        for (let j = i + 1; j < targetArray.length; j++) {
          if (sourceArray.includes(targetArray[i]) && sourceArray.includes(targetArray[j])) {
            return true;
          }
        }
      }
      return false;
    },
    handleDisabledStatus(item){
      this.setDisabledStatus(item, "季", "季");
      this.setDisabledStatus(item, "月", "月");
      this.setDisabledStatus(item, "次", "次");
      this.setDisabledStatus(item, "不申报", "不申报");
      let months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
      let isMonthIncluded = months.some(month => item.types.includes(month));
      let status = this.containsAnyTwo(months, item.types)
      if (item.types.includes("年")) {
        item.options.forEach(v => {
          if (["月", "季", "次", "不申报"].includes(v.value)) {
            v.disabled = true;
          } else {
            v.disabled = false;
          }
        });
        if (isMonthIncluded) {
          item.options.forEach(e => {
            if (!["月", "季", "次", "不申报"].includes(e.value)) {
              e.disabled = !item.types.includes(e.value);
            }
          });
        }
      } else if (isMonthIncluded) {
        let jyArr = status ? ["月", "季", "次", "不申报","年"] : ["月", "季", "次", "不申报"];
        item.options.forEach(e => {
          e.disabled = jyArr.includes(e.value) ? true : false;
        });
      } else if (
        !item.types.includes("季") &&
        !item.types.includes("月") &&
        !item.types.includes("次") &&
        !item.types.includes("不申报")
      ) {
        item.options.forEach(v => {
          v.disabled = false;
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.filter-container {
  display: flex;
  justify-content: space-between;
}

.icon-baocun,
.icon-shanchu {
  margin: 0 5px;
  cursor: pointer;
  color: var(--themeColor,#17a2b8);
  font-size: 18px;
  line-height: 24px;
}

.el-radio {
  height: 30px;
}
</style>
