<template>
<el-dialog :close-on-click-modal="false" destroy-on-close title="新增"  v-model="dialogVisible"  width="50%" >
  <el-form ref="form" :model="form" label-width="80px" :rules="rules">
    <el-form-item label="税种" prop="collectionProject">
      <el-select v-model="form.collectionProject" placeholder="请选择税种" size="small" filterable>
        <el-option
          v-for="item in collection"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="申报周期" prop="type">
      <el-select v-model="form.type" placeholder="请选择申报周期" size="small">
        <el-option
          v-for="item in type"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </el-form-item>
  </el-form>
  <div>
    
  </div>
  <div class="dialog-footer">
    <el-button @click="dialogVisible = false" size="small">取消</el-button>
    <el-button type="primary" @click="taxCategoryAdd()" size="small">确定</el-button>
  </div>
</el-dialog>
  
</template>

<script>
import { saveTaxCategory } from '@/api/taxCalculation.js'
export default {
  name:'addComInfo',
  data() {
    return {
      dialogVisible: false,
      form: {
        collectionProject: '',
        type:''
      },
      comId: '',
      collection:[
        {
          value: '增值税',
          label:'增值税'
        },
        {
          value: '城镇土地使用税',
          label:'城镇土地使用税'
        },{
          value: '房产税',
          label:'房产税'
        },{
          value: '印花税',
          label:'印花税'
        },{
          value: '资源税',
          label:'资源税'
        },{
          value: '工会经费',
          label:'工会经费'
        },{
          value: '消费税',
          label:'消费税'
        },{
          value: '残疾人就业保障金',
          label:'残疾人就业保障金'
        },{
          value: '企业所得税',
          label:'企业所得税'
        },{
          value: '个人所得税',
          label:'个人所得税'
        },{
          value: '教育费附加',
          label:'教育费附加'
        },{
          value: '地方教育附加',
          label:'地方教育附加'
        },{
          value: '水利建设专项收入',
          label:'水利建设专项收入'
        },{
          value: '资产负债表',
          label:'资产负债表'
        },{
          value: '利润表',
          label:'利润表'
        },{
          value: '现金流量表',
          label:'现金流量表'
        },{
          value: '城市生活垃圾处置费',
          label:'城市生活垃圾处置费'
        },{
          value: '城市维护建设税',
          label:'城市维护建设税'
        },{
          value: '文化事业建设费',
          label:'文化事业建设费'
        },
        {
          value:"环境保护税",
          label:"环境保护税"
        },
      ],
      type:[
        {
          value:'月',
          label:'月报'
        },{
          value:'季',
          label:'季报'
        },{
          value:'次',
          label:'次报'
        },{
          value:'不申报',
          label:'不申报'
        },{
          value:'01',
          label:'一月'
        },{
          value:'02',
          label:'二月'
        },{
          value:'03',
          label:'三月'
        },{
          value:'04',
          label:'四月'
        },{
          value:'05',
          label:'五月'
        },{
          value:'06',
          label:'六月'
        },{
          value:'07',
          label:'七月'
        },{
          value:'08',
          label:'八月'
        },{
          value:'09',
          label:'九月'
        },{
          value:'10',
          label:'十月'
        },{
          value:'11',
          label:'十一月'
        },{
          value:'12',
          label:'十二月'
        }
      ],
      rules: {
        collectionProject: [{ required: true, message: '请选择税种', trigger: 'change' }],
        type: [{ required: true, message: '请选择申报周期', trigger: 'change' }],
      },
    }
  },
  methods: {
    resetList() {
      this.form = {
        collectionProject: '',
        type:''
      }
    },
    openSalary(comId) {
      this.resetList()
      this.comId = comId
      this.dialogVisible = true
    },
    taxCategoryAdd() {
      this.$refs['form'].validate((valid) => {
        if(valid) {
          let param = {
            comId:this.comId,
            collectionProject:this.form.collectionProject,
            type:this.form.type
          }
          saveTaxCategory(
            {list:[param]}
          ).then(res => {
            if(res.data.msg == "success"){
              this.$message({
                message: '新增成功',
                type: 'success'
              });
              this.dialogVisible = false
              this.$emit("success")
            }
          })
        }
        
      })
      
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-footer {
  display: flex;
  justify-content: end;
}
</style>