<template>
  <div v-loading="loading">
    <el-scrollbar :style="{ height: contentStyleObj }">
      <div class="common_dialog_box clearfix">
        <!-- <div style="float:right">
          <qzf-video vid="4467be537495bcad65a69d90d53acfa5_4"></qzf-video>
        </div> -->
        <div class="common_title">基本信息</div>
        <div class="item_one clearfix">
          <el-form
            :inline="true"
            style="width:1200px"
            label-width="160px"
            class="com_info"
            ref="dataForm" 
            :rules="rules" 
            :model="basic1"
            size="small"
          >
            <el-form-item label="企业名称" @click.prevent="() => {}"  prop="name">
              <input-common v-model:modelValue="basic1.name" placeholder="请输入企业名称"></input-common>
            </el-form-item>
            <el-form-item label="纳税人识别号" @click.prevent="() => {}" prop="taxNo">
              <input-common v-model:modelValue="basic1.taxNo" placeholder="请输入纳税人识别号"></input-common>
            </el-form-item>
            <el-form-item label="纳税人资格" @click.prevent="() => {}" prop="type">
              <selecttaxtype
                :status="false"
                v-model:type="basic1.type"
                @select="selects"
                type="tax_type"
                style="width:200px"
              ></selecttaxtype>
            </el-form-item>
            <el-form-item label="公司类型" @click.prevent="() => {}" prop="companyType">
              <el-select
                v-model="basic1.companyType"
                placeholder="请选择公司类型"
                style="width:200px"
                size="small"
              >
               <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="会计准则" @click.prevent="() => {}">
              <el-select
                v-model="basic1.accountSystem"
                placeholder="请选择会计准则"
                size="small"
                style="width:200px"
                :disabled="basic1.mpid == 1 || (basic1.status == 1 && basic1.setUpStatusJz != 6) || basic1.status != 1"
              >
                <el-option
                  label="小企业会计准则2013版"
                  value="小企业会计准则2013版"
                  size="small"
                  :disabled="basic1.accountSystem != '小企业会计准则2013版' && basic1.accountSystem != '一般企业会计准则' && basic1.accountSystem != '一般企业会计准则(已执行新金融准则)' && basic1.accountSystem != '企业会计制度'"
                >
                </el-option>
                <el-option
                  label="一般企业会计准则(未执行-常规科目)"
                  value="一般企业会计准则"
                  size="small"
                  :disabled="basic1.accountSystem != '小企业会计准则2013版' && basic1.accountSystem != '一般企业会计准则' && basic1.accountSystem != '一般企业会计准则(已执行新金融准则)' && basic1.accountSystem != '企业会计制度'"
                >
                </el-option>
                <el-option
                  label="一般企业会计准则(未执行-全科目)"
                  value="一般企业会计准则(全科目)"
                  size="small"
                  :disabled="basic1.accountSystem != '一般企业会计准则(全科目)' && basic1.accountSystem != '一般企业会计准则(已执行新金融准则)(全科目)'"
                >
                </el-option>
                <el-option
                  label="一般企业会计准则(已执行-常规科目)"
                  value="一般企业会计准则(已执行新金融准则)"
                  size="small"
                  :disabled="basic1.accountSystem != '小企业会计准则2013版' && basic1.accountSystem != '一般企业会计准则' && basic1.accountSystem != '一般企业会计准则(已执行新金融准则)' && basic1.accountSystem != '企业会计制度'"
                >
                </el-option>
                <el-option
                  label="一般企业会计准则(已执行-全科目)"
                  value="一般企业会计准则(已执行新金融准则)(全科目)"
                  size="small"
                  :disabled="basic1.accountSystem != '一般企业会计准则(全科目)' && basic1.accountSystem != '一般企业会计准则(已执行新金融准则)(全科目)'"
                >
                </el-option>
                <el-option
                  label="企业会计制度"
                  value="企业会计制度"
                  size="small"
                  :disabled="basic1.accountSystem != '小企业会计准则2013版' && basic1.accountSystem != '一般企业会计准则' && basic1.accountSystem != '一般企业会计准则(已执行新金融准则)' && basic1.accountSystem != '企业会计制度'"
                >
                </el-option>
                <el-option
                  :disabled="basic1.accountSystem != '民办非'"
                  label="民间非营利"
                  value="民办非"
                  size="small"
                ></el-option>
                <el-option
                  :disabled="basic1.accountSystem != '农业合作社'"
                  label="农村合作社"
                  value="农业合作社"
                  size="small"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属税务总局" @click.prevent="() => {}" prop="districtCode">
              <selectcity
                v-model:citys="basic1.districtCode"
                @success="discodeChange()"
                :status="false"
                style="width:200px"
              ></selectcity>
            </el-form-item>
            <el-form-item label="主管税务机关" @click.prevent="() => {}">
              <input-common v-model:modelValue="basic1.taxAuthorities" placeholder="请输入主管税务机关"></input-common>
              <el-tooltip content="系统不支持一家公司同时申报多个主管税务机关，如有此情况请去扣缴端手动申报" placement="top">
                <i class="iconfont icon-wenhao" style="color:red;cursor: pointer;font-size:13px;position:absolute;right:-20px"></i>
              </el-tooltip>
            </el-form-item>
            <el-form-item label="公司注册时间" @click.prevent="() => {}">
              <el-date-picker :picker-options="pickerOptions" v-model="basic1.comSetUpYear" type="date" clearable placeholder="公司注册时间" style="width:200px;" size="small" value-format="YYYY-MM-DD"></el-date-picker>
            </el-form-item>
            <el-form-item label="启用账期" @click.prevent="() => {}">
              <span style="width:200px;display: inline-block;">
                {{ basic1.startAccountPeriod }}
              </span>
            </el-form-item>
            <el-form-item label="截止账期" @click.prevent="() => {}">
              <el-date-picker :picker-options="pickerOptions" v-model="basic1.duePeriod" type="month" clearable placeholder="截止月" style="width:200px;" size="small" value-format="YYYYMM"></el-date-picker>
              <el-tooltip content="截止账期为最后一个结账账期，非必选，可设置为空" placement="top">
                <i class="iconfont icon-wenhao" style="color:red;cursor: pointer;font-size:13px;position:absolute;right:-20px"></i>
              </el-tooltip>
            </el-form-item>
            <el-form-item label="所得税类型">
              <el-radio-group v-model="basic1.qysdsStatus">
                <el-radio :label="1">A类</el-radio>
                <el-radio :label="2">B类</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <el-form label-width="160px" width="1200px">
            <el-form-item label="公司地址" @click.prevent="() => {}">
              <input-common v-model:modelValue="basic1.gsdz" placeholder="请输入公司地址" style="width:986px"></input-common>
            </el-form-item>
            <el-form-item label="经营范围" @click.prevent="() => {}">
              <input-common v-model:modelValue="basic1.jyfw" placeholder="请输入经营范围" style="width:986px" inputType="textarea"></input-common>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <el-form
        ref="dataForm" 
        :rules="rules" 
        :model="basic1"
        :inline="true"
        style="width:1200px"
        label-width="160px"
        class="com_info"
      >
        <div class="common_dialog_box clearfix">
          <div class="common_title">电子税局登录信息
            <!-- <span v-if="basic1.loginId != 0" style="color:red">注：已绑定办税人信息，取消绑定可修改 <span style="color:var(--themeColor,#17a2b8);cursor: pointer;" @click="cancel()">点击取消</span> </span> -->
            </div>
          <div class="item_one clearfix" >
            <div class="add_btn">
              <el-button type="primary" size="small" @click="bindLogin" icon="Plus" plain :disabled="!$buttonStatus('qyxx_qyjbxx_bjdlxx')"
                >新增</el-button
              >
            </div>
            <el-table
              :data="basic1.loginIdsTemp"
              style="width: 91%;margin:10px auto"
              border
              :loading="tableLoading"
            >
              <el-table-column prop="loginMethod" label="登录方式" min-width="120" />
              <el-table-column prop="zzridno" label="用户名/手机号" min-width="120" />
              <el-table-column prop="zzrmn" label="密码" min-width="120" />
              <el-table-column prop="idNo" label="代理机构号/账号" min-width="120" />
              <el-table-column prop="idNo" label="短信接收方式" min-width="120">
                <template #default="scope">
                  {{ scope.row.yzmType == 1 ? "手动接收" : "自动接收" }}
                </template>
              </el-table-column>
              <el-table-column label="操作" width="95">
                <template #default="scope">
                  <el-button size="small" link @click="editLogin(scope.row)" :disabled="!$buttonStatus('qyxx_qyjbxx_bjdlxx')"
                    >编辑</el-button
                  >
                  <el-button size="small" link type="danger" :disabled="basic1?.loginIdsTemp?.length == 1 || !$buttonStatus('qyxx_qyjbxx_jbdlxx')" @click="delLogin(scope.row)"
                    >解绑</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <!-- v-if="basic1.loginId == 0" -->
            <!-- <el-form-item
              label="绑定登录信息"
              @click.prevent="() => {}"
              prop="loginIds"
              style="width:1200px"
            >  
              <el-select
                v-model="basic1.loginIds"
                placeholder="请选择"
                clearable
                filterable
                style="width: 200px"
                size="small"
                multiple
                collapse-tags
                collapse-tags-tooltip
              >
                <el-option
                  style="color:#17a2b8"
                  label="+新增"
                  value="add"
                  @click="addLoginUser"
                ></el-option>
                <el-option
                  v-for="item in optionsLogin"
                  :key="item.id"
                  :label="item.loginMethod + item.zzridno"
                  :value="item.id"
                >
                  <span>{{ item.loginMethod }}</span>
                  <span>({{ item.zzridno }})</span></el-option
                >
              </el-select>
            </el-form-item> -->
            <!-- <el-form-item
              v-if="userPass.loginMethod"
              label="税局登录方式"
              @click.prevent="() => {}"
              prop="loginMethod"
            >
              <el-select
                :disabled="!userPass.loginMethodDisabled"
                v-model="basic1.loginMethod"
                placeholder="请选择税局登录方式"
                size="small"
                @change="changeLoginMethod"
                style="width: 200px"
              >
                <template v-if="userPass.loginMethodOption">
                  <el-option
                    v-for="item in userPass.loginMethodOption"
                    :key="item"
                    :label="item"
                    :value="item"
                  ></el-option>
                </template>
                <template v-else>
                  <el-option
                    v-for="item in tax_login"
                    :key="item.value"
                    :label="item.value"
                    :value="item.value"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="userPass.idNo"
              :label="userPass.idNoLabel"
              @click.prevent="() => {}"
              prop="idNo"
            >
              <el-input
                :placeholder="userPass.idNoText"
                v-model="basic1.idNo"
                size="small"
              />
            </el-form-item>
            <el-form-item
              v-if="userPass.password"
              :label="userPass.passwordLabel"
              @click.prevent="() => {}"
              prop="password"
            >
              <el-input
                placeholder="税务密码"
                v-model="basic1.password"
                size="small"
              />
            </el-form-item>
            <el-form-item
              label="选择身份"
              v-if="userPass.xzsf"
              @click.prevent="() => {}"
              prop="xzsf"
            >
              <el-select
                v-model="basic1.xzsf"
                placeholder="选择身份"
                size="small"
              >
                <el-option label="法人" value="法人"></el-option>
                <el-option label="财务负责人" value="财务负责人"></el-option>
                <el-option label="办税人" value="办税人"></el-option>
                <el-option label="购票员" value="购票员"></el-option>
                <el-option label="其他人员" value="其他人员"></el-option>
                <el-option label="附办税人" value="附办税人"></el-option>
                <el-option label="普通管理员" value="普通管理员"></el-option>
                <el-option label="实名办税" value="实名办税"></el-option>
                <el-option label="领票人" value="领票人"></el-option>
                <el-option label="开票员" value="开票员"></el-option>
                <el-option label="办税员" value="办税员"></el-option>
                <el-option label="法定代表人" value="法定代表人"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="userPass.jbrIdno"
              label="经办人"
              @click.prevent="() => {}"
              prop="jbr"
            >
              <el-input
                placeholder="经办人"
                v-model="basic1.jbr"
                size="small"
              />
            </el-form-item>
            <el-form-item
              v-if="userPass.jbrIdno"
              label="经办人身份证号"
              @click.prevent="() => {}"
              prop="jbrIdno"
            >
              <el-input
                placeholder="经办人身份证号"
                v-model="basic1.jbrIdno"
                size="small"
              />
            </el-form-item>
            <el-form-item
              v-if="userPass.zzrmn"
              :label="userPass.zzrmnLabel"
              @click.prevent="() => {}"
              prop="zzrmn"
            >
              <el-input
                :placeholder="userPass.zzrmnText"
                v-model="basic1.zzrmn"
                size="small"
              />
            </el-form-item>
            <el-form-item
              v-if="userPass.zzrxm"
              label="办税人/自然人姓名"
              @click.prevent="() => {}"
              prop="zzrxm"
            >
              <el-input
                placeholder="办税人/自然人姓名"
                v-model="basic1.zzrxm"
                size="small"
              />
            </el-form-item>
            <el-form-item
              v-if="userPass.zzridno"
              :label="userPass.zzridnoTextLabel"
              @click.prevent="() => {}"
              prop="zzridno"
            >
              <el-input
                :placeholder="userPass.zzridnoText"
                v-model="basic1.zzridno"
                size="small"
              />
            </el-form-item>
            <el-form-item v-if="(userPass.loginMethod == '新版登录' || userPass.loginMethod == '代理登录') && basic1.districtCode != '440000' && basic1.districtCode != '120000'" label="办税手机号:" prop="mobileRec"  @click.prevent="(()=>{})">
              <el-input v-model.trim="basic1.mobileRec" style="width:100%;" size="small" type="text"  v-if="this.$buttonStatus('sqdl_sjhxs')"/>
              <span v-else>
                {{ basic1.mobileRec.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")}}
              </span>
            </el-form-item> -->
          </div>
        </div>
        <div class="common_dialog_box clearfix">
          <div class="common_title">个税登录信息
            <!-- <span v-if="basic1.loginId != 0" style="color:red">注：已绑定办税人信息，取消绑定可修改 <span style="color:var(--themeColor,#17a2b8);cursor: pointer;" @click="cancel()">点击取消</span> </span> -->
            </div>
          <div class="item_one clearfix" >
            <!-- v-if="basic1.loginId == 0" -->
            <el-form-item label="个税登录方式" @click.prevent="() => {}" prop="personalLoginType">
              <el-select
                v-model="basic1.personalLoginType"
                placeholder="请选择个税登录方式"
                size="small"
                style="width: 200px"
                @change="changePersonalType"
              >
                <el-option
                  v-for="item in personal_type"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="userPass.personalIdno"
              label="个税实名登录账号"
              @click.prevent="() => {}"
              prop="personalIdno"
            >
              <input-common v-model:modelValue="basic1.personalIdno" placeholder="请输入个税实名登录账号"></input-common>
            </el-form-item>
            <el-form-item
              v-if="userPass.personalTaxPwd"
              label="个税密码"
              @click.prevent="() => {}"
              prop="personalTaxPwd"
            >
              <input-common v-model:modelValue="basic1.personalTaxPwd" placeholder="请输入个税密码"></input-common>
            </el-form-item>
          </div>
        </div>
        <div class="common_dialog_box clearfix" v-if="basic1.districtCode == '360000'">
          <div class="common_title">社保登录信息
            <!-- <span v-if="basic1.loginId != 0" style="color:red">注：已绑定办税人信息，取消绑定可修改 <span style="color:var(--themeColor,#17a2b8);cursor: pointer;" @click="cancel()">点击取消</span> </span> -->
            </div>
          <div class="item_one clearfix" >
            <!-- v-if="basic1.loginId == 0" -->
            <el-form-item label="社保登录方式:" class="item" @click.prevent="(()=>{})"> 
            <el-select v-model="basic1.socialLoginMethod" placeholder="请选择社保登录方式" style="width:200px;" size="small">
              <el-option v-for="item in sb_type" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="社保密码:"  class="item" @click.prevent="(()=>{})">
            <input-common v-model:modelValue="basic1.socialPassword" placeholder="请输入社保密码"></input-common>
          </el-form-item>
          </div>
        </div>
      </el-form>

      <div class="common_dialog_box clearfix">
        <div class="common_title">联系人</div>
        <div class="item_one clearfix">
          <el-form
            :inline="true"
            style="width:1200px"
            label-width="160px"
            class="com_info"
          >
            <el-form-item label="法人代表" @click.prevent="() => {}">
              <input-common v-model:modelValue="basic1.manager" placeholder="请输入法人代表"></input-common>
            </el-form-item>
            <el-form-item label="身份证" @click.prevent="() => {}">
              <input-common v-model:modelValue="basic1.managerIdno" placeholder="请输入法人代表身份证"></input-common>
            </el-form-item>
            <el-form-item label="联系电话" @click.prevent="() => {}">
              <input-common v-model:modelValue="basic1.managerTel" placeholder="请输入法人代表联系电话"></input-common>
            </el-form-item>
          </el-form>

          <el-form
            :inline="true"
            style="width:1200px"
            label-width="160px"
            class="com_info"
          >
            <el-form-item label="财务负责人" @click.prevent="() => {}">
              <input-common v-model:modelValue="basic1.linkman" placeholder="请输入财务负责人"></input-common>
            </el-form-item>
            <el-form-item label="身份证" @click.prevent="() => {}">
              <input-common v-model:modelValue="basic1.linkmanIdno" placeholder="请输入财务负责人身份证"></input-common>
            </el-form-item>
            <el-form-item label="联系电话" @click.prevent="() => {}">
              <input-common v-model:modelValue="basic1.linkmanTel" placeholder="请输入财务负责人联系电话"></input-common>
            </el-form-item>
          </el-form>

          <el-form
            :inline="true"
            style="width:1200px"
            label-width="160px"
            class="com_info"
          >
            <el-form-item label="办税人" @click.prevent="() => {}">
              <input-common v-model:modelValue="basic1.taxCollectorName" placeholder="请输入办税人"></input-common>
            </el-form-item>
            <el-form-item label="身份证" @click.prevent="() => {}">
              <input-common v-model:modelValue="basic1.taxCollectorIdno" placeholder="请输入办税人身份证"></input-common>
            </el-form-item>
            <el-form-item label="联系电话" @click.prevent="() => {}">
              <input-common v-model:modelValue="basic1.taxCollectorNameTel" placeholder="请输入办税人联系电话"></input-common>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="common_dialog_box clearfix">
        <div class="common_title">基础配置</div>
        <div class="item_one clearfix">
          <el-form
            :inline="true"
            style="width:1200px"
            label-width="160px"
            class="com_info"
          >
            <div>
              <el-form-item label="开启存货">
                <div style="width: 200px;">
                  <el-radio-group v-model="basic1.inventoryStatus">
                    <el-radio :label="1">开启</el-radio>
                    <el-radio :label="0">关闭</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>

              <el-form-item label="存货开启账期">
                <el-date-picker
                  disabled
                  size="small"
                  v-model="basic1.inventoryTime"
                  type="month"
                  placeholder="请选择账期"
                  value-format="YYYYMM"
                  format="YYYYMM"
                  style="width: 200px;"
                ></el-date-picker>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="辅助核算">
                <div style="width: 200px;">
                  <el-radio-group v-model="basic1.fzhsStatus">
                    <el-radio :label="1">开启</el-radio>
                    <el-radio :label="0">关闭</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>

              <el-form-item label="辅助核算开启账期">
                <el-date-picker
                  disabled
                  size="small"
                  v-model="basic1.fzhsTime"
                  type="month"
                  placeholder="请选择账期"
                  value-format="YYYYMM"
                  format="YYYYMM"
                  style="width: 200px;"
                ></el-date-picker>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>
      <!-- </el-form> -->
      <!-- 按钮的loading 先不用了吧 -->
      <!-- :loading="loading" -->
      
    </el-scrollbar>
    <div style="text-align: right;">
      <el-button
        @click="savebasic"
        type="primary"
        size="small"
        v-if="$buttonStatus('qyxx_qyjbxx_bcxg')"
      >
        <i
          class="iconfont icon-baocun"
          style="margin-right:4px;font-size: 14px;"
        ></i
        ><span> 保存修改</span>
      </el-button>
    </div>
  </div>
  <taxManForm ref="taxManForm" @success="getLoginsList"></taxManForm>
  <updateInfo ref="updateInfo" @success="getLoginsList"/>
</template>

<script>
import selecttaxtype from "@/components/Screening/selecttaxtype";
import selectcity from "@/components/Screening/selectcity";
import { eaCompanyInfo, saveEaCompany,companyLoginUserUpdate,companyLoginComList,companyLoginUserList,cancelCompanyLoginCom  } from "@/api/company";
import { disCodeUserPass } from "@/utils";
import { COMPANY_TYPE_OPTION } from '@/utils/commonData'
import taxManForm from "@/components/authorizedLogin/taxManForm.vue";
import updateInfo from "../../ea-setting/components/updateInfo.vue";
export default {
  name: "basicInform",
  components: { selecttaxtype, selectcity,taxManForm,updateInfo },
  data() {
    let validateMobile = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入报税手机号"));
      }
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("请输入正确的报税手机号"));
      } else {
        return callback();
      }
    };
    return {
      loginOpen: true,
      pickerOptions:{
        disabledDate(time) {
          return time.getTime() > new Date();
        },
      },
      options:COMPANY_TYPE_OPTION,
      listData: [],
      basic1: {
        jbr: "",
        jbrIdno: "",
        name: "",
        taxNo: "",
        districtCode: [],
        type: "",
        personalTaxPwd: "",
        linkman: "",
        linkmanTel: "",
        manager: "",
        managerTel: "",
        loginIds: [],
        loginIdsTemp: [],
      },
      value: "",
      radio: "",
      userPass: {},
      tax_login: [
        {
          label: "账号密码",
          value: "账号密码",
        },
        {
          label: "短信登录",
          value: "短信登录",
        },
        {
          label: "代理业务登录",
          value: "代理业务登录",
        },
      ],
      personal_type: [
        {
          value: "实名登录",
          label: "实名登录",
        },
        {
          value: "申报密码登录",
          label: "申报密码登录",
        },
      ],
      sb_type:[{
        value:'账号密码登录',
        label:'账号密码登录'
      }],
      period: "",
      parma: {},
      loading:false,
      rules: {
        name: [
          { required: true, message: "公司名不能为空", trigger: "change" }
        ],
        districtCode: [
          { required: true, message: "所属税务总局不能为空", trigger: "change" }
        ],
        taxNo: [
          { required: true, message: "请输入纳税人识别编号", trigger: "change" },
          { min: 15,  message: '税号至少15位', trigger: 'blur' }
        ],
        type: [
          { required: true, message: "请选择纳税人资格", trigger: "change" }
        ],
        companyType: [
          { required: true, message: "请选择企业类型", trigger: "change" }
        ],
        loginMethod: [
          { required: true, message: "请选择国税登录方式", trigger: "change" }
        ],
        personalLoginType: [
          { required: true, message: "请选择个税登录方式", trigger: "change" }
        ],
        personalIdno: [
          { required: true, message: "请输入个税实名账号", trigger: "change" }
        ],
        personalTaxPwd: [
          { required: true, message: "请输入个税申报密码", trigger: "change" }
        ],
        zzrmn: [
          { required: true, message: "请输入密码", trigger: "change" }
        ],
        zzridno: [
          { required: true, message: "请输入办税员的身份证号码", trigger: "change" }
        ],
        zzrxm: [
          { required: true, message: "请输入办税员姓名", trigger: "change" }
        ],
        password: [
          { required: true, message: "请输入国税登录密码", trigger: "change" }
        ],
        xzsf: [
          { required: true, message: "请选择身份", trigger: "change" }
        ],
        idNo: [
          { required: true, message: "请输入正确的证件号码", trigger: "change" }
        ],
        idType: [
          { required: true, message: "请选择证件类型", trigger: "change" }
        ],
        jbrIdno: [
          { required: true, message: "请输入经办人身份证号", trigger: "change" }
        ],
        jbr: [
          { required: true, message: "请输入经办人", trigger: "change" }
        ],
        mobileRec: [
          { required: true, validator: validateMobile, trigger: "change" }
        ],
        loginIds:[
          { required: true, message: "请绑定登录信息", trigger: "change" }
        ]
      },
      optionsLogin:[],
      tableLoading:false,
    };
  },
  created() {
    this.contentStyleObj = this.$getHeight(190);
    let color = window.location.hostname == 'daizhang.jsptax.com' ? '#1dc6e1' : 'var(--themeColor,#17a2b8)'
    document.getElementById("app").style.setProperty("--headerBasicColor", color);
    this.init();
  },
  mounted(){
    // if (this.$store.getters["user/model"] == "财务核算") {
    //   this.$store.dispatch('tagsView/delCachedViewByName', "bookkeeping")
    // }
  },
  methods: {
    addLoginUser(){
      this.basic1.loginIds = []
      this.$refs.taxManForm.init()
    },
    init() {
      this.getList();
      // this.getLoginUserList()
    },
    getList() {
      this.loading = true
      let comId = this.$store.getters["commons/params"].editCustomerComId * 1;
      if (this.$store.getters["user/comInfo"].comId * 1) {
        comId = this.$store.getters["user/comInfo"].comId * 1;
        this.period = this.$store.getters["user/comInfo"].period;
        this.parma = {
          comId,
          period: this.period,
        };
      } else if (
        this.$store.getters["commons/params"].editCustomerComId.id * 1
      ) {
        comId = this.$store.getters["commons/params"].editCustomerComId.id;
        this.parma = {
          comId,
        };
      }
      eaCompanyInfo(this.parma).then((res) => {
        this.loading = false
        this.basic1 = res.data.data.info;
        this.userPass = disCodeUserPass(
          this.basic1.districtCode,
          this.basic1.loginMethod
        );
        this.changePersonalType();
        this.getLoginsList();
      });
    },
    getLoginUserList(){
      companyLoginUserList({}).then((res) => {
        if (res.data.msg == "success") {
          if (res.data.data && res.data.data.length != 0) {
            this.optionsLogin = res.data.data.list;
          } else {
            // this.$qzfMessage("请先点击授权管理新增办税人信息", 1);
          }
        }
      });
    },
    getLoginsList(){
      this.tableLoading = true
      let tempList =[]
      companyLoginComList({comId:this.basic1.id}).then((res) => {
        this.tableLoading = false
        this.basic1.loginIdsTemp = res.data.data.list ? res.data.data.list : [];
        this.basic1.loginIdsTemp.forEach(item =>{
          tempList.push(item.id)
        })
        this.basic1.loginIds = tempList
      });
    },
    // 选择个税登录方式时
    changePersonalType() {
      if (this.basic1.personalLoginType == "实名登录") {
        this.userPass.personalIdno = true;
      } else {
        this.userPass.personalIdno = false;
      }
    },
    changeLoginMethod() {
      this.userPass = disCodeUserPass(
        this.basic1.districtCode,
        this.basic1.loginMethod
      );
      if (this.basic1.personalLoginType == "实名登录") {
        this.userPass.personalIdno = true;
      } else {
        this.userPass.personalIdno = false;
      }
    },
    savebasic() {
      // 判断已结账未结账
      this.basic1.comId = this.basic1.id;
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          if(this.basic1.loginIds.length == 0){
            this.$qzfMessage('请绑定登录信息',1)
            return
          }
          this.loading = true
          if (this.$store.getters["user/comInfo"].comId) {
            // if (this.$checkSettleStatus() != "") {
            //   this.$message.error(this.$checkSettleStatus());
            //   return;
            // }
            
            saveEaCompany({ list: [this.basic1] }).then((res) => {
              this.loading = false
              if (res.data.msg == "success") {
                if (res.data.data.fail_total) {
                  this.$qzfMessage(res.data.data.fail_list[0].remark, 2);
                } else {
                  this.$qzfMessage("保存成功");
                  if (
                    this.basic1.accountSystem !=
                    this.$store.getters["user/comInfo"].comKj
                  ) {
                    this.$store.getters[
                      "user/comInfo"
                    ].comKj = this.basic1.accountSystem;
                    this.$bus.emit("accountSystemUpdate");
                  }
                  if(this.$store.getters["user/comInfo"].comType != this.basic1.type){
                    this.$store.dispatch("commons/getSubjects")
                    this.$store.dispatch("commons/getBusinessType")
                    this.$bus.emit('eaSubjectUpdate')
                    this.$bus.emit('habbitSettingBookUpdate')
                    this.$store.getters["user/comInfo"].comType = this.basic1.type
                  }
                  this.getList();
                }
                if (this.$store.getters["user/model"] == "财务核算") {
                  this.$store.dispatch('tagsView/delCachedViewByName', "bookkeeping")
                }
              }
            });
          } else {
            saveEaCompany({ list: [this.basic1] }).then((res) => {
              this.loading = false
              if (res.data.msg == "success") {
                if (res.data.data.fail_total) {
                  this.$qzfMessage(res.data.data.fail_list[0].remark, 2);
                } else {
                  this.$qzfMessage("保存成功");
                  if (
                    this.basic1.accountSystem !=
                    this.$store.getters["user/comInfo"].comKj
                  ) {
                    this.$store.getters[
                      "user/comInfo"
                    ].comKj = this.basic1.accountSystem;
                    this.$bus.emit("accountSystemUpdate");
                  }
                  // this.$store.dispatch('tagsView/delCachedViewByName', "addCustomer")
                  this.$bus.emit("addCustomerUpdate");
                  this.getList();
                }
              }
              if (this.$store.getters["user/model"] == "财务核算") {
                this.$store.dispatch('tagsView/delCachedViewByName', "bookkeeping")
              }
            });
         }
        }
      })
    },
    // 选择地区时
    discodeChange(type) {
      this.basic1.loginMethod = "";
      this.userPass = disCodeUserPass(
        this.basic1.districtCode,
        this.basic1.loginMethod
      );
      this.basic1.loginMethod = this.userPass.loginMethod;
      if (this.basic1.personalLoginType == "实名登录") {
        this.userPass.personalIdno = true;
      } else {
        this.userPass.personalIdno = false;
      }
    },
    cancel(){
      this.$confirm("确认解除已绑定的办税人信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        companyLoginUserUpdate({
          ids: [this.basic1.id],
          loginId: 0,
        }).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("解绑成功");
            this.getList()
          }
        });
      });
    },
    //新增登录信息
    bindLogin(){
      this.$refs.updateInfo.init([this.basic1.id],this.basic1.loginIds)
    },
    //删除登录方式
    delLogin(row){
      this.$confirm("确认解绑吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        cancelCompanyLoginCom({ids:[row.loginComId]}).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("解绑成功")
            this.getLoginsList()
          }
        })
      });
    },
    //编辑登录信息
    editLogin(row){
      this.$refs.taxManForm.init(row)
    }
  }
};
</script>

<style lang="scss" scoped>
.com_info .el-select .el-input__wrapper {
  margin: 0 0 0 0;
}
.com_info {
  :deep(.el-input__wrapper) {
    width: 200px;
  }
}
.com_info .el-input {
  margin: 0 0 0 0;
}

.bottom_tax .el-table .cell {
  line-height: 23px !important;
}
.bottom_tax {
  width: 100%;
  margin-top: 20px;
}
</style>

<style lang="scss" scoped>
.common_dialog_box {
  width: 100%;
  margin: 0 auto;
}
.common_dialog_box .common_title {
  font-size: 14px;
  color: var(--themeColor, #17a2b8);
  font-weight: 600;
  line-height: 28px;
  border-bottom: 1px solid #c2c2c2;
  margin-bottom: 5px;
}
.common_dialog_box .common_title span {
  color: var(--themeColor, #17a2b8);
  margin-left: 30px;
  font-weight: normal !important;
}
.common_dialog_box .item:nth-child(2n + 1) {
  margin-left: 0;
}
.new_dialog .el-button--primary {
  background: var(--themeColor, #17a2b8);
  border-color: var(--themeColor, #17a2b8);
}
.common_dialog_box .el-select {
  margin: 0 0 0 0 !important;
  width: 100%;
}
.add_btn{
  width: 91%;
  margin:0 auto;
  text-align:right;
}
:deep(.el-table tr th){
  background: var(--headerBasicColor) !important;
}
</style>
<style lang="scss">
.com_info .el-input__inner {
  margin: 0 0 0 0;
  // width:200px
}
</style>
